import React, { useState, useContext } from 'react';
import { Button } from '@ebth/design-system';

import Layout from '../components/layout';
import SEO from '../components/seo';
import InputField from '../components/InputField';

import './thank-you.scss';
import { CookieContext } from '../context/CookieProvider';

const CONSIGNMENT_PARAM = 'Mail_In_Consignment';

const hiddenInputs = {
  tfa_3: 'salesforceid',
  tfa_60: 'opportunity_id',
};

const TermsAndConditionsPage = () => {
  const [formValues, setFormValues] = useState({});
  const context = useContext(CookieContext);

  const handleChange = (e) => setFormValues({ ...formValues, [e.target.id]: e.target.value });

  const getFormValue = (field) => formValues[field] || '';

  return (
    <Layout pageName="Seller T&C Form" hideActions>
      <SEO title="Consignment Terms & Conditions" />
      <div className="thank-you-header">
        <div className="thank-you-header__text-container">
          <h1 className="thank-you-header__text">Consignment Terms</h1>
        </div>
      </div>
      <div className="terms-and-conditions-layout narrow-wrapper">
        <div className="">
          <div className="wForm" id="tfa_0-WRPR" dir="ltr">
            <div className="codesection" id="code-tfa_0" />
            <h3 className="wFormTitle" id="tfa_0-T">
              Terms &amp; Conditions
            </h3>
            <form
              method="post"
              action="https://www.tfaforms.com/responses/processor"
              className="hintsBelow labelsAbove"
              id="tfa_0"
            >
              <div className="oneField field-container-D" id="tfa_4-D">
                <label
                  id="tfa_4-L"
                  htmlFor="tfa_4"
                  className="label preField reqMark"
                >
                  Payable Name
                </label>
                <div className="inputWrapper">
                  <InputField
                    maxLength="40"
                    name="tfa_4"
                    autocomplete="given-name"
                    onChange={handleChange}
                    value={getFormValue('tfa_4')}
                    id="tfa_4"
                    required
                  />
                </div>
              </div>

              <div className="oneField field-container-D" id="tfa_61-D">
                <label id="tfa_61-L" htmlFor="tfa_61" className="label preField">
                  Attention/Company (optional)
                </label>
                <div className="inputWrapper">
                  {/* <input type="text" id="tfa_61" name="tfa_61" value="" placeholder="" maxLength="40" title="Attention/Company (optional)" className="" /> */}
                  <InputField
                    maxLength="40"
                    name="tfa_61"
                    onChange={handleChange}
                    value={getFormValue('tfa_61')}
                    id="tfa_61"
                  />
                </div>
              </div>

              <div className="oneField field-container-D" id="tfa_5-D">
                <label
                  id="tfa_5-L"
                  htmlFor="tfa_5"
                  className="label preField reqMark"
                >
                  Street Address
                </label>
                <div className="inputWrapper">
                  {/* <input type="text" id="tfa_5" name="tfa_5" value="" placeholder="" aria-required="true" title="Street Address" className="required" />			 */}
                  <InputField
                    maxLength="40"
                    name="tfa_5"
                    onChange={handleChange}
                    value={getFormValue('tfa_5')}
                    id="tfa_5"
                    required
                  />
                </div>
              </div>

              <div className="oneField field-container-D" id="tfa_6-D">
                <label id="tfa_6-L" htmlFor="tfa_6" className="label preField ">
                  Floor / Suite
                </label>
                <div className="inputWrapper">
                  {/* <input type="text" id="tfa_6" name="tfa_6" value="" placeholder="" title="Floor / Suite" className="" /> */}
                  <InputField
                    maxLength="40"
                    name="tfa_6"
                    onChange={handleChange}
                    value={getFormValue('tfa_6')}
                    id="tfa_6"
                  />
                </div>
              </div>

              <div className="oneField field-container-D" id="tfa_7-D">
                <label
                  id="tfa_7-L"
                  htmlFor="tfa_7"
                  className="label preField reqMark"
                >
                  City
                </label>
                <div className="inputWrapper">
                  {/* <input type="text" id="tfa_7" name="tfa_7" value="" placeholder="" aria-required="true" title="City" className="required" /> */}
                  <InputField
                    maxLength="40"
                    name="tfa_7"
                    onChange={handleChange}
                    value={getFormValue('tfa_7')}
                    id="tfa_7"
                    required
                  />
                </div>
              </div>

              <div className="oneField field-container-D" id="tfa_9-D">
                <label
                  id="tfa_9-L"
                  htmlhtmlFor="tfa_9"
                  className="label preField reqMark"
                >
                  State
                </label>
                <div className="inputWrapper">
                  <select
                    id="tfa_9"
                    name="tfa_9"
                    title="State"
                    aria-required="true"
                    className="required"
                    required
                  >
                    <option value="">Please select...</option>
                    <option value="tfa_10" id="tfa_10" className="">
                      AK
                    </option>
                    <option value="tfa_11" id="tfa_11" className="">
                      AL
                    </option>
                    <option value="tfa_12" id="tfa_12" className="">
                      AR
                    </option>
                    <option value="tfa_13" id="tfa_13" className="">
                      AZ
                    </option>
                    <option value="tfa_14" id="tfa_14" className="">
                      CA
                    </option>
                    <option value="tfa_15" id="tfa_15" className="">
                      CO
                    </option>
                    <option value="tfa_16" id="tfa_16" className="">
                      CT
                    </option>
                    <option value="tfa_62" id="tfa_62" className="">
                      DC
                    </option>
                    <option value="tfa_17" id="tfa_17" className="">
                      DE
                    </option>
                    <option value="tfa_18" id="tfa_18" className="">
                      FL
                    </option>
                    <option value="tfa_19" id="tfa_19" className="">
                      GA
                    </option>
                    <option value="tfa_20" id="tfa_20" className="">
                      HI
                    </option>
                    <option value="tfa_21" id="tfa_21" className="">
                      IA
                    </option>
                    <option value="tfa_22" id="tfa_22" className="">
                      ID
                    </option>
                    <option value="tfa_23" id="tfa_23" className="">
                      IL
                    </option>
                    <option value="tfa_24" id="tfa_24" className="">
                      IN
                    </option>
                    <option value="tfa_25" id="tfa_25" className="">
                      KS
                    </option>
                    <option value="tfa_26" id="tfa_26" className="">
                      KY
                    </option>
                    <option value="tfa_27" id="tfa_27" className="">
                      LA
                    </option>
                    <option value="tfa_28" id="tfa_28" className="">
                      MA
                    </option>
                    <option value="tfa_29" id="tfa_29" className="">
                      MD
                    </option>
                    <option value="tfa_30" id="tfa_30" className="">
                      ME
                    </option>
                    <option value="tfa_31" id="tfa_31" className="">
                      MI
                    </option>
                    <option value="tfa_32" id="tfa_32" className="">
                      MN
                    </option>
                    <option value="tfa_33" id="tfa_33" className="">
                      MO
                    </option>
                    <option value="tfa_34" id="tfa_34" className="">
                      MS
                    </option>
                    <option value="tfa_35" id="tfa_35" className="">
                      MT
                    </option>
                    <option value="tfa_36" id="tfa_36" className="">
                      NC
                    </option>
                    <option value="tfa_37" id="tfa_37" className="">
                      ND
                    </option>
                    <option value="tfa_38" id="tfa_38" className="">
                      NE
                    </option>
                    <option value="tfa_39" id="tfa_39" className="">
                      NH
                    </option>
                    <option value="tfa_40" id="tfa_40" className="">
                      NJ
                    </option>
                    <option value="tfa_41" id="tfa_41" className="">
                      NM
                    </option>
                    <option value="tfa_42" id="tfa_42" className="">
                      NV
                    </option>
                    <option value="tfa_43" id="tfa_43" className="">
                      NY
                    </option>
                    <option value="tfa_44" id="tfa_44" className="">
                      OH
                    </option>
                    <option value="tfa_45" id="tfa_45" className="">
                      OK
                    </option>
                    <option value="tfa_46" id="tfa_46" className="">
                      OR
                    </option>
                    <option value="tfa_47" id="tfa_47" className="">
                      PA
                    </option>
                    <option value="tfa_48" id="tfa_48" className="">
                      RI
                    </option>
                    <option value="tfa_49" id="tfa_49" className="">
                      SC
                    </option>
                    <option value="tfa_50" id="tfa_50" className="">
                      SD
                    </option>
                    <option value="tfa_51" id="tfa_51" className="">
                      TN
                    </option>
                    <option value="tfa_52" id="tfa_52" className="">
                      TX
                    </option>
                    <option value="tfa_53" id="tfa_53" className="">
                      UT
                    </option>
                    <option value="tfa_54" id="tfa_54" className="">
                      VA
                    </option>
                    <option value="tfa_55" id="tfa_55" className="">
                      VT
                    </option>
                    <option value="tfa_56" id="tfa_56" className="">
                      WA
                    </option>
                    <option value="tfa_57" id="tfa_57" className="">
                      WI
                    </option>
                    <option value="tfa_58" id="tfa_58" className="">
                      WV
                    </option>
                    <option value="tfa_59" id="tfa_59" className="">
                      WY
                    </option>
                  </select>
                </div>
              </div>

              <div className="oneField field-container-D" id="tfa_8-D">
                <label
                  id="tfa_8-L"
                  htmlFor="tfa_8"
                  className="label preField reqMark"
                >
                  Zip Code
                </label>
                <div className="inputWrapper">
                  {/* <input type="text" id="tfa_8" name="tfa_8" value="" placeholder="" aria-required="true" min="00000" max="99999" title="Zip Code" className="validate-integer required" /> */}
                  <InputField
                    maxLength="40"
                    name="tfa_8"
                    onChange={handleChange}
                    value={getFormValue('tfa_8')}
                    id="tfa_8"
                    required
                  />
                </div>
              </div>

              <div
                className="oneField field-container-D"
                id="tfa_1-D"
                role="group"
                aria-required="true"
                aria-labelledby="tfa_1-L"
              >
                <label
                  id="tfa_1-L"
                  htmlFor="tfa_1"
                  className="label preField reqMark ebth-tc"
                >
                  <a
                    href="https://www.ebth.com/consignment-terms"
                    target="_blank"
                  >
                    EBTH Consignment Terms and Conditions
                  </a>
                </label>
                <div className="inputWrapper">
                  <span id="tfa_1" className="choices vertical required">
                    <span className="oneChoice">
                      <input
                        type="checkbox"
                        value="tfa_2"
                        className="show-checkbox"
                        id="tfa_2"
                        name="tfa_2"
                      />
                      <label
                        className="label postField"
                        id="tfa_2-L"
                        htmlFor="tfa_2"
                      >
                        I agree to the Terms &amp; Conditions
                      </label>
                    </span>
                  </span>
                </div>
              </div>

              <input
                type="hidden"
                id="tfa_3"
                name="tfa_3"
                value={context ? context.tfa_3 : ''}
              />
              <input
                type="hidden"
                id="tfa_60"
                name="tfa_60"
                value={context ? context.tfa_60 : ''}
              />
              <div className="actions" id="tfa_0-A">
                {/* <input type="submit" className="primaryAction" id="submit_button" value="Submit" /> */}
                <Button
                  type="submit"
                  buttonStyle="primary"
                  buttonSize="xl"
                  id="submit-button"
                  className="u-py1 u-px4 u-mx2 u-my2"
                >
                  Submit
                </Button>
              </div>

              <div style={{ clear: 'both' }} />
              <input
                type="hidden"
                value="4649290"
                name="tfa_dbFormId"
                id="tfa_dbFormId"
              />
              <input
                type="hidden"
                value=""
                name="tfa_dbResponseId"
                id="tfa_dbResponseId"
              />
              <input
                type="hidden"
                value="80b3260cbb16898cb6a4791ae21b7349"
                name="tfa_dbControl"
                id="tfa_dbControl"
              />
              <input
                type="hidden"
                value="16"
                name="tfa_dbVersionId"
                id="tfa_dbVersionId"
              />
              <input
                type="hidden"
                value=""
                name="tfa_switchedoff"
                id="tfa_switchedoff"
              />
            </form>
          </div>
        </div>

        {context && context.tfa_60 === CONSIGNMENT_PARAM && (
          <div id="mail-in" className="soft-hide terms-row margin-top-xl terms">
            <h3>What to Expect Next</h3>
            <span className="dash" />
            <div>
              <ul className="next-list">
                <li className="next-list__item">
                  <span className="next-list__number">1</span> After submitting
                  this form you will receive an email with a link to print your
                  shipping label and packing slip.
                </li>
                <li className="next-list__item">
                  <span className="next-list__number">2</span> After we receive
                  your package our experts will photograph and write
                  descriptions detailing your items.
                </li>
                <li className="next-list__item">
                  <span className="next-list__number">3</span> Our Expert
                  Curators will look for the right opportunity to list your items
                  in the best sale possible.
                </li>
                <li className="next-list__item">
                  <span className="next-list__number">4</span> You will be
                  notified when your items are listed on EBTH.com.
                </li>
                <li className="next-list__item">
                  <span className="next-list__number">5</span> After your items
                  sell you will receive a check in approximately 20 business
                  days.
                </li>
              </ul>
            </div>
            <div>
              <table className="table--commission">
              <tr><th>Selling Item Price</th><th>EBTH Commission</th><th>Seller Take Home</th></tr>
                <tr><td>&gt; $0 - &#8804; $30</td><td>100%</td><td>0%</td></tr>
                <tr><td>&gt; $31 - &#8804; $60</td><td>$30</td><td>Final Sale Price less $30</td></tr>
                <tr><td>&gt; $60 - &#8804; $250</td><td>50%</td><td>50%</td></tr>
                <tr><td>&gt; $250 - &#8804; $500</td><td>40%</td><td>60%</td></tr>
                <tr><td>&gt; $500 - &#8804; $1000</td><td>30%</td><td>70%</td></tr>
                <tr><td>&gt; $1000</td><td>20%</td><td>80%</td></tr>
              </table>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default TermsAndConditionsPage;
